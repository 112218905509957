/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-return-await */
/* eslint-disable no-useless-catch */
import {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
  useContext
} from "react";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AsYouType, isValidPhoneNumber } from "libphonenumber-js";
import {
  useCreateOrganization,
  useGetOrganization,
  useUpdateOrganization
} from "../../api/organizations";
import useCatalog from "./useCatalog";
import {
  ERROR_ICON,
  MESSAGE,
  ORGANIZATIONS_ICON,
  EMAIL_REGEX
} from "../../constants/common.constants";
import Permissions from "../../permissions/permissions";
import { useGetUsersCount } from "../../api/users";
import { IAnyPropertyNameAndStringValue } from "../../types/common.types";
import { IOrganization } from "../../contexts/types/organization.types";
import SnackbarContext from "../../contexts/snackbar.context";
import {
  INCIDENTS_URL,
  ORGANIZATIONS_URL,
  ORGANIZATION_USERS_URL
} from "../../constants/urls.constants";
import { buildUrl } from "../../utils/string.utils";
import { organizationBodyMapper } from "../../mappers/organizationMappers";

export default function useOrganization() {
  const { id: urlId } = useParams();
  const navigate = useNavigate();
  const { catalog, isLoadingCatalog } = useCatalog();
  const { Organization } = Permissions();
  const getOrg = useGetOrganization();
  const getUsersCount = useGetUsersCount();
  const createOrg = useCreateOrganization();
  const updateOrg = useUpdateOrganization();

  const { showSnackbar } = useContext(SnackbarContext);

  const [name, setName] = useState("");
  const [enableUsers, setEnableUsers] = useState(false);
  const [id, setId] = useState(urlId !== "new" ? urlId : null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingUserCount, setIsLoadingUserCount] = useState(false);
  const [usersCount, setUsersCount] = useState<number>(0);

  const isCreateMode = useMemo(() => !id, [id]);
  const browserTimezone = useMemo(
    () => Intl.DateTimeFormat()?.resolvedOptions()?.timeZone,
    []
  );

  const [initialValues, setInitialValues] = useState({
    description: "",
    name: "",
    timezone: isCreateMode ? browserTimezone : "",
    rules_engagement: "",
    escalation_name: "",
    escalation_email: "",
    escalation_phone: "",
    connection_type: "",
    incident_handler: "",
    escalation_id: "",
    quota: 2,
    edr_type: "",
    provider: "",
    handler_email: "",
    handler_phone: "",
    residency_region: ""
  });

  const localUser = useSelector((state: any) => state.user.profile);

  const getOrgRef = useRef<boolean>(false);
  const isUserCountRunning = useRef<boolean>(false);

  const loadCatalogCollection = useCallback(
    (collection: IAnyPropertyNameAndStringValue) => {
      if (!Object.keys(catalog).length) {
        return {};
      }
      return collection;
    },
    [catalog]
  );

  const headerTitle: string = useMemo(
    () => (!isCreateMode && name ? name : "New organization"),
    [isCreateMode, name]
  );

  const residencyRegions = useMemo(
    () => loadCatalogCollection(catalog.residency_region),
    [loadCatalogCollection, catalog]
  );

  const timeZones = useMemo(
    () => loadCatalogCollection(catalog.timezones),
    [loadCatalogCollection, catalog]
  );

  const connectionTypes = useMemo(
    () => loadCatalogCollection(catalog.connection_types),
    [loadCatalogCollection, catalog.connection_types]
  );

  const incidentHandlers = useMemo(
    () => loadCatalogCollection(catalog.incident_handlers),
    [loadCatalogCollection, catalog]
  );

  const edrTypes = useMemo(
    () => loadCatalogCollection(catalog.edr_types),
    [loadCatalogCollection, catalog]
  );

  const loadDetails = useCallback(
    (data: IOrganization) => {
      setEnableUsers(Organization.sections.users.canNavigate(localUser.role));
      setName(data.name);
      setInitialValues({
        description: data.description || "",
        name: data.name,
        timezone: data.timezone || "",
        rules_engagement: data.rules_engagement || "",
        escalation_name: data.escalation_name || "",
        escalation_email: data.escalation_email || "",
        escalation_id: data.escalation_id || "",
        escalation_phone: new AsYouType("US").input(
          data.escalation_phone || ""
        ),
        connection_type: data.connection_type ? data.connection_type[0] : "",
        incident_handler: data.incident_handler || "",
        quota: data.quota || 2,
        edr_type: data.edr_type || "",
        provider: data.provider || "",
        handler_email: data.handler_email || "",
        handler_phone: new AsYouType("US").input(data.handler_phone || ""),
        residency_region: ""
      });
    },
    [Organization.sections.users, localUser.role]
  );

  const retrieveData = useCallback(async () => {
    try {
      setIsLoadingData(true);
      const data = await getOrg(`${id}`);
      loadDetails(data);
      setIsLoadingData(false);
    } catch (error: any) {
      setIsLoadingData(false);
      console.error(
        `Error getting organization ${id}. Status ${error.status}. ${error}`
      );
      showSnackbar({
        text: `Unable to retrieve organization data. ${error}`,
        type: "error",
        icon: ERROR_ICON
      });
      navigate(buildUrl(INCIDENTS_URL));
    }
  }, [getOrg, id, loadDetails, navigate, showSnackbar]);

  const retrieveUsersCount = useCallback(async () => {
    try {
      if (isUserCountRunning.current) return;
      setIsLoadingUserCount(true);
      isUserCountRunning.current = true;
      const userQuantity = await getUsersCount(`${id}`);
      setUsersCount(userQuantity);
      setIsLoadingUserCount(false);
    } catch (error: any) {
      setIsLoadingUserCount(false);
      console.error(
        `Error getting users count from organization ${id}. Status ${error.status}. ${error}`
      );
    }
  }, [getUsersCount, id]);

  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  const handleManageUsers = useCallback(
    () => navigate(buildUrl(ORGANIZATION_USERS_URL, id!)),
    [id, navigate]
  );

  const notificationComplete = useCallback(() => {
    if (urlId === "new" && isCreateMode) {
      navigate(buildUrl(ORGANIZATIONS_URL));
    }
  }, [isCreateMode, navigate, urlId]);

  const handleCreate = useCallback(
    async (body: IOrganization) => {
      try {
        const response = await createOrg(body);
        setId(response.id);
        setEnableUsers(Organization.sections.users.canNavigate(localUser.role));
        return response;
      } catch (error) {
        setId(null);
        setEnableUsers(false);
        throw error;
      }
    },
    [Organization.sections.users, createOrg, localUser.role]
  );

  const handleUpdate = useCallback(
    async (body: IOrganization) => {
      try {
        return await updateOrg(`${id}`, body);
      } catch (error) {
        throw error;
      }
    },
    [id, updateOrg]
  );

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        const body = organizationBodyMapper(values);
        if (isCreateMode) await handleCreate(body);
        else await handleUpdate(body);

        const text = isCreateMode
          ? `Please check your email with the confirmation of ${name} creation`
          : "Organization saved";

        showSnackbar({
          text,
          type: MESSAGE.info,
          icon: ORGANIZATIONS_ICON,
          onClose: notificationComplete
        });

        if (isCreateMode) navigate(buildUrl(ORGANIZATIONS_URL));
      } catch (error: any) {
        const text = isCreateMode
          ? `Error creating organization ${name}: ${error}`
          : `Error updating organization ${name}`;
        console.error(`${text}. Status ${error.status}. ${error}`);
        showSnackbar({ text, type: MESSAGE.error, icon: ERROR_ICON });
      }
    },
    [
      isCreateMode,
      handleCreate,
      handleUpdate,
      name,
      showSnackbar,
      notificationComplete,
      navigate
    ]
  );

  const validationSchema = Yup.object().shape({
    description: Yup.string().required("Description is required"),
    name: Yup.string().required("Name is required"),
    timezone: Yup.string().required("Timezone is required"),
    rules_engagement: Yup.string().required("ROE are required"),
    escalation_name: Yup.string().required("Escalation name is required"),
    escalation_email: Yup.string()
      .required("Escalation email is required")
      .email("Invalid email")
      .matches(EMAIL_REGEX, "Invalid email"),
    escalation_phone: Yup.string()
      .required("Escalation Phone is required")
      .test("wrong-number-format", "Invalid Phone Number", (value) =>
        isValidPhoneNumber(value, "US")
      ),
    connection_type: Yup.string().required("Connection type is required"),
    incident_handler: Yup.string().required("Incident handler is required"),
    quota: Yup.string().required("Quota is required"),
    edr_type: Yup.string().required("EDR is required"),
    provider: Yup.string(),
    handler_email: Yup.string().when("isCreateMode", {
      is: true,
      then: (schema: any) =>
        schema
          .required("Email is required")
          .email("Invalid email")
          .matches(EMAIL_REGEX, "Invalid email"),
      otherwise: (schema: any) => schema
    }),
    handler_phone: Yup.string().when("isCreateMode", {
      is: true,
      then: (schema: any) =>
        schema.test(
          "wrong-number-format",
          "Invalid PhoneS Number",
          (value: any) => isValidPhoneNumber(value || "", "US")
        ),
      otherwise: (schema: any) => schema
    }),
    residency_region: Yup.string().when("isCreateMode", {
      is: true,
      then: (schema: any) => schema.required("Residency region is required"),
      otherwise: (schema: any) => schema
    })
  });

  useEffect(() => {
    if (usersCount) return;
    if (enableUsers && id) retrieveUsersCount();
  }, [usersCount, enableUsers, retrieveUsersCount, id]);

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      edr_type: initialValues.edr_type || Object.keys(edrTypes)[1],
      connection_type:
        initialValues.connection_type || Object.keys(connectionTypes)[0]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalog]);

  useEffect(() => {
    if (getOrgRef.current) return;
    getOrgRef.current = true;
    if (id) retrieveData();
  }, [id, retrieveData]);

  return {
    validationSchema,
    isLoadingData,
    isLoadingUserCount,
    isLoadingCatalog,
    headerTitle,
    isCreateMode,
    enableUsers,
    usersCount,
    edrTypes,
    residencyRegions,
    timeZones,
    connectionTypes,
    incidentHandlers,
    initialValues,
    onSubmit,
    handleCancel,
    handleManageUsers
  };
}
