import React, { useContext, useMemo } from "react";
import "./eventDetails.scss";
import { NavLink } from "react-router-dom";
import Header from "../../components/header/header";
import Icon from "../../components/icon/icon";
import ActionBar from "../../components/actionBar/actionBar";
import useEventDetails from "./useEventDetails";
import SnackbarContext from "../../contexts/snackbar.context";
import {
  COPY_ICON,
  PANTHER_INCIDENTS_ICON
} from "../../constants/common.constants";
import {
  INCIDENTS_URL,
  PANTHER_INCIDENTS_URL
} from "../../constants/urls.constants";
import { buildUrl } from "../../utils/string.utils";
import SessionContext from "../../contexts/session.context";
import { EventDetailInfoProviderContext } from "./eventDetailDataProvider";

export default function EventDetails() {
  const incidentsIcon = `${process.env.REACT_APP_CDN_IMAGES}/incidents.svg`;
  const copyIcon = `${process.env.REACT_APP_CDN_IMAGES}/copy.svg`;
  const edrViewIcon = `${process.env.REACT_APP_CDN_IMAGES}/link.svg`;
  const { data, incidentURL, name, title } = useEventDetails();

  const { isSIEM } = useContext(EventDetailInfoProviderContext);
  const headerIcon = (
    <Icon
      image={isSIEM ? PANTHER_INCIDENTS_ICON : incidentsIcon}
      alt="Incidents"
    />
  );

  const { isMdrRole } = useContext(SessionContext);

  const { showSnackbar } = useContext(SnackbarContext);

  const getLinkClassName = ({ isActive }: any) =>
    isActive ? "navLink navLink--active" : "navLink navLink--inactive";

  const onClickCopy = () => {
    navigator.clipboard
      .writeText(JSON.stringify(data))
      .then(() => {
        showSnackbar({
          text: "Event details copied to clipboard",
          type: "info",
          icon: COPY_ICON
        });
      })
      .catch((reason) => {
        console.error("Error copying event details to clipboard.", reason);
        showSnackbar({
          text: "Error copying event details to clipboard",
          type: "error",
          icon: COPY_ICON
        });
      });
  };

  const edrLink = useMemo(() => data.link, [data]);

  const getIndent = (indent: number) => {
    let entity = "";

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < indent; i++) {
      entity += "\t";
    }
    return entity;
  };

  const getItem = (key: string | null, value: string, level: number) => {
    if (!key) {
      return (
        <pre>
          <p>
            <span>{getIndent(level)}</span>
            {`"${value}"`},
          </p>
        </pre>
      );
    }

    if (key === "link") {
      return (
        <pre>
          <p>
            <span>{getIndent(level)}</span>
            {`"${key}"`}:{" "}
            <a href={value} target="_blank" rel="noreferrer noopener">
              {value},
            </a>
          </p>
        </pre>
      );
    }

    if (key === "COMMAND_LINE" || key === "FILE_PATH") {
      return (
        <pre>
          <p>
            <span>{getIndent(level)}</span>
            {`"${key}"`}:{" "}
            <span className="eventDetails__filePath">{`${value}`},</span>
          </p>
        </pre>
      );
    }

    if (key === "ext_ip" || key === "int_ip") {
      return (
        <pre>
          <p>
            <span>{getIndent(level)}</span>
            {`"${key}"`}:{" "}
            <span className="eventDetails__ip">{`${value}`},</span>
          </p>
        </pre>
      );
    }

    if (typeof value === "number") {
      return (
        <pre>
          <p>
            <span>{getIndent(level)}</span>
            {`"${key}"`}:{" "}
            <span className="eventDetails__number">{`${value}`},</span>
          </p>
        </pre>
      );
    }

    return (
      <pre>
        <p>
          <span>{getIndent(level)}</span>
          {`"${key}"`}: {`"${value}"`},
        </p>
      </pre>
    );
  };

  const getElement = (key: string | null, collection: any, level: number) => {
    if (!collection) {
      return getItem(key, collection, level);
    }

    if (Array.isArray(collection)) {
      return (
        <pre>
          <p>
            <span>{getIndent(level)}</span>
            {`"${key}"`}: <span className="eventDetails__brace">&#91;</span>
          </p>
          {Object.values(collection).map((element, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <pre key={index}>{getElement(null, element, level + 1)}</pre>
          ))}
          <p>
            <span>{getIndent(level)}</span>
            <span className="eventDetails__brace">&#93;,</span>
          </p>
        </pre>
      );
    }

    if (typeof collection === "object") {
      return (
        <>
          {!key ? (
            <p>
              <span>{getIndent(level)}</span>
              <span className="eventDetails__brace">&#123;</span>
            </p>
          ) : (
            <p>
              <span>{getIndent(level)}</span>
              {`"${key}"`}: <span className="eventDetails__brace">&#123;</span>
            </p>
          )}
          {Object.keys(collection).map((element, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <pre key={index}>
              {getElement(element, collection[element], level + 1)}
            </pre>
          ))}
          <p>
            <span>{getIndent(level)}</span>
            <span className="eventDetails__brace">&#125;,</span>
          </p>
        </>
      );
    }

    return getItem(key, collection, level);
  };

  return (
    <div className="eventDetailsContainer">
      <Header icon={headerIcon}>
        <>
          <NavLink
            to={buildUrl(isSIEM ? PANTHER_INCIDENTS_URL : INCIDENTS_URL)}
            className={getLinkClassName}
          >
            Incidents
          </NavLink>
          <span className="eventDetailsContainer__separator">{">"}</span>
          <div className="eventDetailsContainer__title_holder">
            <NavLink to={incidentURL} className={getLinkClassName}>
              {title}
            </NavLink>
          </div>
          <span className="eventDetailsContainer__separator">{">"}</span>
          <h3>{name}</h3>
          {isMdrRole && (
            <ActionBar>
              <>
                <div className="eventDetailsContainer__actions-copy">
                  <Icon image={copyIcon} alt="copy" onClick={onClickCopy} />
                </div>
                <div className="eventDetailsContainer__actions-edrView">
                  <a href={edrLink} target="_blank" rel="noreferrer noopener">
                    <Icon image={edrViewIcon} alt="edr" />
                  </a>
                </div>
              </>
            </ActionBar>
          )}
        </>
      </Header>
      <section className="eventDetailsContainer__details">
        <h5>Details</h5>
        <div className="eventDetailsContainer__data">
          <pre>
            <p>
              <span className="eventDetails__brace">&#123;</span>
            </p>
            {Object.keys(data).map((element, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <code key={index}>{getElement(element, data[element], 1)}</code>
            ))}
            <p>
              <span className="eventDetails__brace">&#125;</span>
            </p>
          </pre>
        </div>
      </section>
    </div>
  );
}
