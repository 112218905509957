import React from "react";
import { Field } from "formik";
import * as Yup from "yup";
import TextArea from "../components/textArea/textArea";
import { WIDTH } from "../constants/common.constants";
import "./closeIncidentForm.scss";

export const closeIncidentValidationSchema = Yup.object().shape({
  closeFinalDisposition: Yup.string().required(
    "Please provide a reason for closure"
  )
});

export default function CloseIncidentForm({
  formik: { handleSubmit, errors, setFieldValue },
  confirmMessage,
  formId
}: any) {
  return (
    <>
      <form onSubmit={handleSubmit} id={formId}>
        <Field
          name="closeFinalDisposition"
          label="Reason for Closure"
          error={errors.closeFinalDisposition}
          touched
          width={WIDTH.max}
          component={TextArea}
          onChange={(event: any) =>
            setFieldValue("closeFinalDisposition", event?.target?.value || "")
          }
        />
      </form>
      <p className="close-incident-confirm-message">{confirmMessage}</p>
    </>
  );
}
