/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from "react";
import "./incidentHeader.scss";
import { NavLink } from "react-router-dom";
import Header from "../../components/header/header";
import Icon from "../../components/icon/icon";
import IncidentHeaderActionBarMdrUser from "./incidentHeaderActionBarMdrUser";
import IncidentHeaderActionBarClientUser from "./incidentHeaderActionBarClientUser";
import { IIncidentHeaderActionBarMdrUserProps } from "./incidentHeader.types";
import {
  INCIDENTS_URL,
  PANTHER_INCIDENTS_URL
} from "../../constants/urls.constants";
import { buildUrl } from "../../utils/string.utils";
import SessionContext from "../../contexts/session.context";
import { IncidentInfoProviderContext } from "../incidentInformationProvider";
import { PANTHER_INCIDENTS_ICON } from "../../constants/common.constants";

export default function IncidentHeader(
  props: IIncidentHeaderActionBarMdrUserProps
) {
  const { isMdrRole } = useContext(SessionContext);

  const incidentsIcon = `${process.env.REACT_APP_CDN_IMAGES}/incidents.svg`;

  const { isSIEM } = useContext(IncidentInfoProviderContext);

  const getLinkClassName = ({ isActive }: any) =>
    isActive ? "navLink navLink--active" : "navLink navLink--inactive";

  return (
    <div className="incidentContainer__header">
      <Header
        icon={
          <Icon
            image={isSIEM ? PANTHER_INCIDENTS_ICON : incidentsIcon}
            alt={`"${isSIEM ? "SIEM incident" : "Incident"}`}
          />
        }
        isLoading={props.isLoadingData}
      >
        <>
          <NavLink
            to={buildUrl(isSIEM ? PANTHER_INCIDENTS_URL : INCIDENTS_URL)}
            className={getLinkClassName}
          >
            Incidents
          </NavLink>
          <span className="incidentContainer__separator">{">"}</span>
          <h3>{props.title}</h3>
          {isMdrRole ? (
            <IncidentHeaderActionBarMdrUser {...props} />
          ) : (
            <IncidentHeaderActionBarClientUser {...props} />
          )}
        </>
      </Header>
    </div>
  );
}
