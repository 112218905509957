import React, { useCallback, useContext, useMemo } from "react";
import "./incidentHeaderActionBarMdrUser.scss";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import Select from "../../components/select/select";
import { SKELETON_VARIANT, STATUS } from "../../constants/common.constants";
import Avatar from "../../components/avatar/avatar";
import SVG from "../../components/svg/svg";
import ActionBar from "../../components/actionBar/actionBar";
import Tooltip, { tooltipTypes } from "../../components/tooltip/tooltip";
import { IncidentContext } from "../incident";
import Menu from "../../components/menu/menu";
import ModalContext from "../../contexts/modal.context";
import { handlersMapper } from "../../mappers/incidentListMappers";
import { reviewActionsMapper } from "../../mappers/incidentDetailsMappers";
import {
  IIncidentHeaderActionBarMdrUserProps,
  IReviewOptions
} from "./incidentHeader.types";
import SkeletonLoader from "../../components/skeleton/skeleton-loader";
import { IncidentInfoProviderContext } from "../incidentInformationProvider";
import CloseIncidentForm, {
  closeIncidentValidationSchema
} from "../../panther-incident-list/closeIncidentForm";

export default function IncidentHeaderActionBarMdrUser(
  props: IIncidentHeaderActionBarMdrUserProps
) {
  const {
    loadingNewPage,
    assignAllowed,
    closeAllowed,
    handlerId,
    handlerInitials,
    handlerName,
    nextDisabled,
    previousDisabled,
    reassignAllowed,
    sendToClientAllowed,
    sendToReviewAllowed,
    showReviewSelect,
    onAssign,
    onClientEscalation,
    onClose,
    onInternalReview,
    onNext,
    onPrevious,
    isAssigning,
    formik
  } = props;

  const { isSIEM } = useContext(IncidentInfoProviderContext);

  const { showModal } = useContext(ModalContext);

  const { catalog } = useContext(IncidentContext);

  const localUser = useSelector((state: any) => state.user.profile);

  const handlers = useMemo(() => {
    const handlerList = { ...(catalog?.handler || {}) };

    if (!Object.keys(handlerList).length) return [];

    if (Reflect.has(handlerList, handlerId)) delete handlerList[handlerId];

    if (Reflect.has(handlerList, localUser.id)) {
      delete handlerList[localUser.id];
      const arrayHandlerList = handlersMapper(handlerList);
      arrayHandlerList.unshift({
        id: localUser.id,
        value: handlerId ? "Reassign to me" : "Assign to me"
      });
      return arrayHandlerList;
    }

    return handlersMapper(handlerList);
  }, [handlerId, catalog, localUser]);

  const previousClassRoot = "incidentContainer__header__actionBar__previous";
  const previousClassName = loadingNewPage
    ? `${previousClassRoot} ${previousClassRoot}--loading`
    : previousDisabled
    ? `${previousClassRoot} ${previousClassRoot}--disabled`
    : previousClassRoot;

  const nextClassRoot = "incidentContainer__header__actionBar__next";
  const nextClassName = loadingNewPage
    ? `${nextClassRoot} ${nextClassRoot}--loading`
    : nextDisabled
    ? `${nextClassRoot} ${nextClassRoot}--disabled`
    : nextClassRoot;

  const dividerIcon = `${process.env.REACT_APP_CDN_IMAGES}/divider.svg`;

  const avatarActionsDisabled = !(assignAllowed || reassignAllowed);

  const assignTooltipText = assignAllowed
    ? "Assign incident"
    : handlerName || handlerInitials;

  const handleReview = (value: string) => {
    const type: any = {
      [STATUS.internalReview]: () =>
        onInternalReview(formik.values.category, formik.values.severity),
      [STATUS.clientEscalation]: () =>
        onClientEscalation(formik.values.category, formik.values.severity),
      [STATUS.closeBenign]: () => onClose(value),
      [STATUS.closeFalsePositive]: () => onClose(value),
      default: () => console.warn("Unknown review value:", value)
    };

    (type[value] || type.default)();
  };

  const showReviewConfirmation = (value: string) => {
    const continueText =
      value === STATUS.clientEscalation ? "Yes, send" : "Yes, close";

    const text =
      value === STATUS.clientEscalation
        ? "Are you sure you want to send this incident to the client?"
        : `Are you sure you want to close this incident as ${catalog.close_reason[value]}`;

    const title =
      value === STATUS.clientEscalation ? "Send to client" : "Close incident";

    showModal({
      title,
      clickAction: () => handleReview(value),
      actionText: continueText,
      content: <p>{text}</p>
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showCloseConfirmation = useCallback(
    (value: string) => {
      showModal({
        title: "Close incident",
        actionText: "Yes, close",
        formId: "closeIncidentForm",
        isForm: true,
        content: (
          <Formik
            onSubmit={({ closeFinalDisposition }: any) => {
              onClose(value, closeFinalDisposition);
            }}
            initialValues={{ closeFinalDisposition: "" }}
            validationSchema={closeIncidentValidationSchema}
            validateOnMount
          >
            {(_formik) => (
              <CloseIncidentForm
                formik={_formik}
                confirmMessage={`Are you sure you want to close this incident as ${catalog.close_reason[value]}`}
                formId="closeIncidentForm"
              />
            )}
          </Formik>
        )
      });
    },
    [catalog.close_reason, onClose, showModal]
  );

  const onReviewChange = (action: string) => {
    if (action !== STATUS.internalReview) {
      if (isSIEM && action !== STATUS.clientEscalation) {
        showCloseConfirmation(action);
      } else {
        showReviewConfirmation(action);
      }
      return;
    }

    handleReview(action);
  };

  const onHandlerSelected = (incidentHandlerId: string) =>
    onAssign(incidentHandlerId);

  const selectValue =
    sendToReviewAllowed || sendToClientAllowed ? "Review" : "Close";

  const reviewItems = useMemo(() => {
    const reviewOptions: IReviewOptions[] = [
      {
        id: STATUS.internalReview,
        value: "Send to review",
        hide: !sendToReviewAllowed
      },
      {
        id: STATUS.clientEscalation,
        value: "Send to client",
        hide: !sendToClientAllowed
      }
    ];

    const closeReasons = closeAllowed
      ? Reflect.has(catalog, "close_reason")
        ? Object.entries(catalog.close_reason)
        : []
      : [];

    return reviewActionsMapper(reviewOptions, closeReasons);
  }, [catalog, closeAllowed, sendToReviewAllowed, sendToClientAllowed]);

  return (
    <ActionBar>
      <>
        {showReviewSelect && (
          <>
            <div className="incidentContainer__header__actionBar__review">
              <Select
                value={selectValue}
                onChange={onReviewChange}
                items={reviewItems}
              />
            </div>
            <img
              className="incidentContainer__header__actionBar__divider"
              src={dividerIcon}
              alt=""
            />
          </>
        )}
        <div className="has-tooltip incidentContainer__header__assign">
          <SkeletonLoader
            variant={SKELETON_VARIANT.avatar}
            loadingVar={isAssigning}
          >
            <Menu
              items={handlers}
              clickAction={onHandlerSelected}
              disabled={avatarActionsDisabled}
              left
            >
              <Avatar text={handlerInitials} />
            </Menu>
          </SkeletonLoader>
          {(assignAllowed || reassignAllowed) && (
            <Tooltip type={tooltipTypes.info} top>
              <p className="incidentContainer__header__tooltip__text">
                {assignTooltipText}
              </p>
            </Tooltip>
          )}
        </div>

        <img
          className="incidentContainer__header__actionBar__divider"
          src={dividerIcon}
          alt=""
        />
        <div className={previousClassName}>
          <SVG file="chevronLeft.svg" onClick={onPrevious} />
        </div>
        <div className={nextClassName}>
          <SVG file="chevronRight.svg" onClick={onNext} />
        </div>
      </>
    </ActionBar>
  );
}
