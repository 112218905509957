import { IMenuItem } from "../components/menu/menu";
import { IReviewOptions } from "../incident/header/incidentHeader.types";
import {
  IAnyPropertyNameAndAnyValue,
  IAnyPropertyNameAndStringValue
} from "../types/common.types";

/* eslint-disable default-param-last */
export const severitiesMapper = (
  severities: IAnyPropertyNameAndStringValue = {},
  icons: IAnyPropertyNameAndStringValue
): IMenuItem[] => {
  const result: IMenuItem[] = [];
  Object.values(severities).forEach((severity, index) =>
    result.push({ id: index.toString(), value: severity, icon: icons[index] })
  );
  return result;
};

export const categoriesMapper = (
  categories: IAnyPropertyNameAndAnyValue = {},
  icons: IAnyPropertyNameAndStringValue
): IMenuItem[] => {
  const result: IMenuItem[] = [];
  Object.keys(categories).forEach((key) => {
    const cats: IMenuItem[] = [];
    Object.keys(categories[key].category).forEach((cat) => {
      cats.push({ id: cat, value: categories[key].category[cat] });
    });
    result.push({
      id: key,
      value: categories[key].name,
      icon: icons[key],
      subItems: cats
    });
  });
  return result;
};

export const reviewActionsMapper = (
  items: IReviewOptions[],
  closeReasons: [string, any][] = []
): IMenuItem[] => {
  const result: IMenuItem[] = items.filter((filter) => !filter.hide).length
    ? [...items, { id: "__divider" }]
    : [];
  closeReasons.forEach(([id, value]) =>
    result.push({ id, value: `Close ${value}` })
  );
  return result;
};

export const timelineCommentsMapper = (timeline: any) =>
  Object.values(timeline)
    .filter((item: any) => item?.comments?.length)
    .map(({ comments, date, day }: any) => ({ date, day, comments }));

export const timelineEventsMapper = (timeline: any) =>
  Object.values(timeline)
    .filter((item: any) => item?.events?.length)
    .map(({ events, date, day }: any) => ({ date, day, events }));

export const eventTimeLineMapper = (eventsArray: any) =>
  Object.values(eventsArray).map(({ events, date, day }: any) => ({
    date,
    day,
    events
  }));
