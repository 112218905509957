import React, { useMemo, useState } from "react";
import "./accordion.scss";
import Icon from "../icon/icon";
import { IHOC } from "../../types/common.types";

type IProps = IHOC & {
  icon?: React.JSX.Element;
  title: string | React.JSX.Element;
  actionBar?: React.JSX.Element | null;
  compressed: boolean;
  closed?: boolean;
};

export default function Accordion({
  icon,
  title,
  actionBar,
  children,
  compressed,
  closed
}: IProps) {
  const dividerIcon = `${process.env.REACT_APP_CDN_IMAGES}/divider.svg`;
  const chevronDown = `${process.env.REACT_APP_CDN_IMAGES}/chevronDown.svg`;
  const isClosed = useMemo(() => closed, [closed]);

  const contentClassRoot = "accordionContainer__content";

  const chevronClassRoot = actionBar
    ? "accordionContainer__chevron"
    : `accordionContainer__chevron accordionContainer__chevron--right`;

  const [chevronClass, setChevronClass] = useState(
    `${chevronClassRoot}${
      isClosed ? " accordionContainer__chevron--close" : ""
    }`
  );
  const [contentClass, setContentClass] = useState(
    `${contentClassRoot} accordionContainer__content--${
      isClosed ? "close" : "open"
    }`
  );

  const onClickHeader = () => {
    if (chevronClass === chevronClassRoot) {
      setChevronClass(`${chevronClassRoot} accordionContainer__chevron--close`);
      setContentClass(`${contentClassRoot} accordionContainer__content--close`);
    } else {
      setChevronClass(chevronClassRoot);
      setContentClass(`${contentClassRoot} accordionContainer__content--open`);
    }
  };

  return (
    <div className="accordionContainer">
      <div
        className={`accordionContainer__header${
          compressed ? " accordionContainer__header--compressed" : ""
        }`}
        onClick={onClickHeader}
      >
        {icon && <div className="accordionContainer__icon">{icon}</div>}
        {title && <div className="accordionContainer__title">{title}</div>}
        {actionBar && (
          <>
            <div className="accordionContainer__actionBar">{actionBar}</div>
            <div className="accordionContainer__divider">
              <Icon image={dividerIcon} />
            </div>
          </>
        )}
        <div className={chevronClass}>
          <Icon image={chevronDown} alt="v" />
        </div>
      </div>
      <div className={contentClass}>{children}</div>
    </div>
  );
}
