import { useFlags } from "launchdarkly-react-client-sdk";
import React, { createContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { IState } from "../types/common.types";
import { ROLES } from "../constants/common.constants";

export type FeatureFlagContext = {
  mdrEnablePantherIntegration: boolean;
};
const featureFlagsContext = createContext<FeatureFlagContext>(
  {} as FeatureFlagContext
);

export function convertFeatureFlagStringToBoolean(
  featureFlagString = ""
): boolean {
  return featureFlagString?.toLocaleLowerCase() === "true" || false;
}

export function FeatureFlagsProvider({ children }: any) {
  const {
    profile: { role }
  } = useSelector(({ user }: IState) => user);
  const { mdrEnablePantherIntegration } = useFlags();
  const flags = useMemo(
    () => ({
      mdrEnablePantherIntegration:
        role === ROLES.mdrAdmin && mdrEnablePantherIntegration
    }),
    [mdrEnablePantherIntegration, role]
  );
  return (
    <featureFlagsContext.Provider value={flags}>
      {children}
    </featureFlagsContext.Provider>
  );
}
// this will be used when flag feature management is not available
export function StaticFeatureFlagsProvider({ children }: any) {
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const staticFlags: FeatureFlagContext = {
    mdrEnablePantherIntegration: false
  };
  return (
    <featureFlagsContext.Provider value={staticFlags}>
      {children}
    </featureFlagsContext.Provider>
  );
}

export default featureFlagsContext;
