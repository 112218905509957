/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useContext, useState } from "react";
import { useSelector } from "react-redux";
import Avatar from "../../components/avatar/avatar";
import Accordion from "../../components/accordion/accordion";
import "./incidentActivityComments.scss";
import Button from "../../components/button/button";
import {
  ERROR_ICON,
  MESSAGE,
  SUCCESS_ICON,
  VARIANT,
  WIDTH
} from "../../constants/common.constants";
import TextArea from "../../components/textArea/textArea";
import IncidentActivityCommentsBar from "./incidentActivityCommentsBar";
import ModalContext from "../../contexts/modal.context";
import SnackbarContext from "../../contexts/snackbar.context";
import FormatDate from "../../formatDate/formatDate";
import { IIncidentActivityCommentsProps } from "./incidentActivity.types";
import getInitials from "../../utils/string.utils";

export default function IncidentActivityComments({
  data,
  editComment = () => {},
  deleteComment = () => {}
}: IIncidentActivityCommentsProps) {
  const { username, user_id, comment, comment_id, created } = data;

  const { showModal, closeModal, setAsyncLoading } = useContext(ModalContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const [text, setText] = useState(comment || "");
  const [textCache, setTextCache] = useState(comment || "");

  const [buttonsDisabled, setButtonsDisabled] = useState(true);

  const initials = getInitials(username);

  const icon = <Avatar text={initials} />;

  const onTextChange = (event: any) => {
    setTextCache(event.target.value);
    setButtonsDisabled(false);
  };

  const onClickCancel = () => {
    setTextCache(text);
    setButtonsDisabled(true);
  };

  const [isLoading, setIsLoading] = useState(false);

  const onClickSave = async () => {
    setIsLoading(true);
    setButtonsDisabled(true);
    const result = await editComment(comment_id, textCache);
    setIsLoading(false);

    if (result) {
      setText(textCache);
      showSnackbar({
        text: "Comment updated",
        type: MESSAGE.info,
        icon: SUCCESS_ICON
      });
    } else
      showSnackbar({
        text: "Error updating comment",
        type: MESSAGE.error,
        icon: ERROR_ICON
      });
  };

  const deleteCommentAction = useCallback(async () => {
    setAsyncLoading(true);
    setButtonsDisabled(true);
    const result = await deleteComment(comment_id);
    setAsyncLoading(false);
    closeModal();

    if (result)
      showSnackbar({
        text: "Comment deleted",
        type: MESSAGE.info,
        icon: SUCCESS_ICON
      });
    else
      showSnackbar({
        text: "Error deleting comment",
        type: MESSAGE.error,
        icon: ERROR_ICON
      });
  }, [comment_id, closeModal, setAsyncLoading, showSnackbar, deleteComment]);

  const onClickDelete = useCallback(
    (event: any) => {
      event.stopPropagation();
      showModal({
        title: "Delete comment",
        clickAction: deleteCommentAction,
        actionText: "Yes, delete",
        content: <p>Are you sure you want to delete the comment?</p>
      });
    },
    [deleteCommentAction, showModal]
  );

  const localUser = useSelector((state: any) => state.user.profile);

  const commentsDisabled = user_id !== localUser.id;

  const actionBar = !commentsDisabled ? (
    <IncidentActivityCommentsBar onDelete={onClickDelete} />
  ) : null;
  const timestamp = new FormatDate(created).dateWithTime;

  const title = (
    <div className="activityComments__title">
      <p>{username}</p>
      <label>{timestamp}</label>
    </div>
  );

  return (
    <Accordion icon={icon} title={title} actionBar={actionBar} compressed>
      <>
        <TextArea
          value={textCache}
          width={WIDTH.max}
          onChange={onTextChange}
          disabled={commentsDisabled || isLoading}
          readOnly={commentsDisabled}
        />
        {!commentsDisabled && (
          <div className="activityComments__actions">
            <Button
              variant={VARIANT.secondary}
              onClick={onClickCancel}
              disabled={buttonsDisabled}
            >
              Cancel
            </Button>
            <Button
              variant={VARIANT.primary}
              onClick={onClickSave}
              loading={isLoading}
              disabled={buttonsDisabled}
            >
              Save
            </Button>
          </div>
        )}
      </>
    </Accordion>
  );
}
