/* eslint-disable no-underscore-dangle */
export default class FormatDate {
  #_dateObj: Date;

  #_number: any = 0;

  constructor(value: number | undefined = undefined, secondsScale = true) {
    // NOTE: disable secondsScale to spot troublesome dates
    this.#_dateObj = value
      ? secondsScale
        ? new Date(value * 1000)
        : new Date(value)
      : new Date();
    this.#_number = Intl.NumberFormat("en-US", {
      minimumIntegerDigits: 2,
      maximumSignificantDigits: 2
    });
  }

  get #dateObj(): Date {
    return this.#_dateObj;
  }

  get #number(): any {
    return this.#_number;
  }

  get dateWithTime(): string {
    let stringTime = "";
    const hours = this.#number.format(this.#dateObj.getUTCHours());
    const minutes = this.#number.format(this.#dateObj.getUTCMinutes());
    const seconds = this.#number.format(this.#dateObj.getUTCSeconds());
    stringTime += `, ${hours}:${minutes}:${seconds}`;

    return `${this.date}${stringTime}`;
  }

  get date(): string {
    return `${this.year}-${this.#number.format(
      this.month
    )}-${this.#number.format(this.day)}`;
  }

  get day(): number {
    return this.#number.format(this.#dateObj.getUTCDate());
  }

  get month(): number {
    return this.#number.format(this.#dateObj.getUTCMonth() + 1);
  }

  get year(): number {
    return this.#dateObj.getUTCFullYear();
  }

  get dayOfWeek(): string {
    const day = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    return day[this.#dateObj.getUTCDay()];
  }

  get epochDate(): number {
    const day = this.#number.format(this.#dateObj.getUTCDate());
    const month = this.#number.format(this.#dateObj.getUTCMonth() + 1);
    const year = this.#dateObj.getUTCFullYear();

    const flatDate = new Date(year, month - 1, day);
    return Math.floor(flatDate.getTime() / 1000.0);
  }
}
