import React, { createContext, useContext, useMemo, useState } from "react";
import "./incident.scss";
import { Formik } from "formik";
import IncidentHeader from "./header/incidentHeader";
import IncidentDetails from "./incidentDetails/incidentDetails";
import IncidentActivity from "./incidentActivity/incidentActivity";
import WarningBanner from "../components/warningBanner/warning-banner";
import SessionContext from "../contexts/session.context";
import { IncidentInfoProviderContext } from "./incidentInformationProvider";

export const IncidentContext = createContext<any>(null);
export const IncidentActivityContext = createContext<any>(null);

export default function Incident() {
  const { isMdrRole } = useContext(SessionContext);

  const {
    id,
    incidentCatalog,
    created,
    close_reason,
    close_final_disposition,
    handlerId,
    headerTitle,
    hostname,
    sensor,
    nextDisabled,
    previousDisabled,
    status,
    severities,
    orgName,
    loadingNewPage,
    isExempt,
    tags,
    isLoadingData,
    closeAllowed,
    sendToClientAllowed,
    sendToReviewAllowed,
    reassignAllowed,
    assignAllowed,
    initials,
    handlerName,
    notesDisabled,
    initialValues,
    validationSchema,
    categories,
    isAssigning,
    handleSendToClient,
    handleSendToReview,
    handleClose,
    handleAssign,
    handlePrevious,
    handleNext,
    handleSaveDescription,
    handleSaveTitle,
    handleSaveUsername,
    handleCategorySelected,
    handleSeveritySelected
  } = useContext(IncidentInfoProviderContext);

  const showReviewSelect =
    closeAllowed || sendToReviewAllowed || sendToClientAllowed;

  const [className, setClassName] = useState(
    isMdrRole
      ? "incidentContainer__mdrUser incidentContainer--width-default"
      : "incidentContainer__clientUser incidentContainer--width-default"
  );

  const onDetailsCollapsed = () => {
    if (isMdrRole) {
      setClassName(
        "incidentContainer__mdrUser--collapsed incidentContainer--width-collapsed"
      );
    } else {
      setClassName(
        "incidentContainer__clientUser--collapsed incidentContainer--width-collapsed"
      );
    }
  };

  const onDetailsExpanded = () => {
    if (isMdrRole) {
      setClassName(
        "incidentContainer__mdrUser incidentContainer--width-default"
      );
    } else {
      setClassName(
        "incidentContainer__clientUser incidentContainer--width-default"
      );
    }
  };

  const activityContext = useMemo(
    () => ({
      notesDisabled
    }),
    [notesDisabled]
  );

  const incidentContext = useMemo(
    () => ({
      id,
      title: headerTitle,
      catalog: incidentCatalog
    }),
    [id, headerTitle, incidentCatalog]
  );

  return (
    <IncidentContext.Provider value={incidentContext}>
      <div className={className}>
        <Formik
          initialValues={initialValues}
          onSubmit={async () => {
            // no-op
          }}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {(formik) => (
            <>
              <IncidentHeader
                title={headerTitle}
                handlerId={handlerId}
                handlerInitials={initials}
                handlerName={handlerName}
                showReviewSelect={showReviewSelect}
                assignAllowed={assignAllowed}
                reassignAllowed={reassignAllowed}
                sendToReviewAllowed={sendToReviewAllowed}
                sendToClientAllowed={sendToClientAllowed}
                closeAllowed={closeAllowed}
                previousDisabled={previousDisabled}
                nextDisabled={nextDisabled}
                loadingNewPage={loadingNewPage}
                onAssign={handleAssign}
                onClientEscalation={handleSendToClient}
                onClose={handleClose}
                onInternalReview={handleSendToReview}
                onPrevious={handlePrevious}
                onNext={handleNext}
                isLoadingData={isLoadingData}
                isAssigning={isAssigning}
                formik={formik}
              />
              {isExempt && isMdrRole && <WarningBanner />}
              <IncidentDetails
                catalog={incidentCatalog}
                created={created}
                status={status}
                hostname={hostname || ""}
                sensor={sensor || ""}
                severities={severities}
                categories={categories}
                orgName={orgName}
                tags={tags || []}
                isLoadingData={isLoadingData}
                onCategorySelected={handleCategorySelected}
                onSaveDescription={handleSaveDescription}
                onSeveritySelected={handleSeveritySelected}
                onSaveTitle={handleSaveTitle}
                onSaveUsername={handleSaveUsername}
                onCollapse={onDetailsCollapsed}
                onExpand={onDetailsExpanded}
                close_reason={close_reason}
                close_final_disposition={close_final_disposition}
                formik={formik}
              />
            </>
          )}
        </Formik>
        <IncidentActivityContext.Provider value={activityContext}>
          <IncidentActivity />
        </IncidentActivityContext.Provider>
      </div>
    </IncidentContext.Provider>
  );
}
