import { useState } from "react";
import { EVENTS, STATUS, TABLE } from "../../constants/common.constants";

export default function useIncidentsContextMenu() {
  const [showMenu, setShowMenu] = useState(false);

  const getStatusStateValues = (row: any) => {
    const statusCellState =
      row.cells[TABLE.INCIDENTS.CELLS.MDR_USERS.status.index].state;

    const valuesSet =
      Reflect.has(statusCellState, "data") &&
      Reflect.has(statusCellState.data, "next") &&
      Reflect.has(statusCellState.data.next, "values");

    if (!valuesSet) {
      return null;
    }

    return statusCellState.data.next.values;
  };

  const statusStateHasValue = (row: any, value: string) => {
    const values = getStatusStateValues(row);
    return values ? Reflect.has(values, value) : false;
  };

  const showAssign = (row: any) => statusStateHasValue(row, STATUS.claimed);

  const showClose = (row: any) => statusStateHasValue(row, STATUS.closed);

  const handleMenuSelected = () => setShowMenu((prevState) => !prevState);

  const handleAssign = (
    incidentId: string,
    handlerId: string,
    rowIndex: string
  ) => {
    setShowMenu(false);

    const event = new CustomEvent(EVENTS.assignIncident, {
      detail: {
        incidentId,
        handlerId,
        rowIndex
      }
    });

    window.dispatchEvent(event);
  };

  return {
    handleAssign,
    handleMenuSelected,
    setShowMenu,
    showAssign,
    showClose,
    showMenu
  };
}
