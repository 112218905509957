import { IMenuItem } from "../components/menu/menu";
import {
  IEscalationContacts,
  IOrganization
} from "../contexts/types/organization.types";
import { IAnyPropertyNameAndStringValue } from "../types/common.types";

export const edrTypesMapper = (
  edrTypes: IAnyPropertyNameAndStringValue
): IMenuItem[] => {
  const result: IMenuItem[] = [];
  Object.keys(edrTypes).forEach((key) =>
    result.push({ id: key, value: edrTypes[key] })
  );
  return result;
};

export const timezonesMapper = (
  timezones: IAnyPropertyNameAndStringValue
): IMenuItem[] => {
  const result: IMenuItem[] = [];
  Object.keys(timezones).forEach((key) =>
    result.push({ id: key, value: timezones[key] })
  );
  return result;
};

export const residencyRegionMapper = (
  residencyRegions: IAnyPropertyNameAndStringValue
): IMenuItem[] => {
  const result: IMenuItem[] = [];
  Object.keys(residencyRegions).forEach((key) =>
    result.push({ id: key, value: residencyRegions[key] })
  );
  return result;
};

export const connectionsMapper = (
  connections: IAnyPropertyNameAndStringValue
): IMenuItem[] => {
  const result: IMenuItem[] = [];
  Object.keys(connections).forEach((key) =>
    result.push({ id: key, value: connections[key] })
  );
  return result;
};

export const organizationListDropdownMapper = (
  organizations: IAnyPropertyNameAndStringValue
): IMenuItem[] => {
  const result: IMenuItem[] = [];
  if (organizations)
    Object.keys(organizations).forEach((key) =>
      result.push({ id: key, value: organizations[key] })
    );
  return result;
};

export const organizationsListMapper = (
  organizations: IOrganization[]
): IAnyPropertyNameAndStringValue => {
  const result: IAnyPropertyNameAndStringValue = {};
  if (organizations)
    organizations.forEach((organization: IOrganization) => {
      result[`${organization.idm_id}`] = organization.name;
    });
  return result;
};

export const organizationBodyMapper = ({
  escalation_name,
  escalation_email,
  escalation_phone,
  escalation_id,
  name,
  description,
  timezone,
  rules_engagement,
  connection_type,
  incident_handler,
  quota,
  edr_type,
  provider,
  residency_region
}: IOrganization): IOrganization => {
  const escalation_contacts: IEscalationContacts = [
    {
      escalation_name,
      escalation_email,
      escalation_phone,
      id: escalation_id
    }
  ];

  return {
    name,
    description,
    timezone,
    rules_engagement,
    escalation_contacts,
    connection_type,
    incident_handler,
    quota: Number(quota),
    edr_type,
    provider,
    residency_region
  };
};
