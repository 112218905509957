import { IAnalistNotesBody, IEventBody } from "../event/event.types";
import { useDelete, useGet, usePatch, usePost } from "./api";

export function useGetEvents() {
  const getApi = useGet();

  async function run(incidentId: string, nextPageToken: string) {
    const endpoint = `${process.env.REACT_APP_API_EVENTS}/events/${incidentId}${
      nextPageToken ? `?nextpagetoken=${nextPageToken}` : ""
    }`;
    getApi.setEndpoint(endpoint);
    return getApi.runReturnResponse(getApi.getOptions());
  }
  return run;
}

export function useUpdateEvent() {
  const patchApi = usePatch();

  async function run(id: string, body: IEventBody) {
    patchApi.setEndpoint(`${process.env.REACT_APP_API_EVENTS}/event/${id}`);
    patchApi.setBody(body);
    return patchApi.run(patchApi.getOptions());
  }
  return run;
}

export function useUpdateAnalystNotes() {
  const patchApi = usePatch();

  async function run(id: string, body: IAnalistNotesBody) {
    patchApi.setEndpoint(
      `${process.env.REACT_APP_API_EVENTS}/event/${id}/analystnote`
    );
    patchApi.setBody(body);
    return patchApi.run(patchApi.getOptions());
  }
  return run;
}

export function useDeleteAnalystNotes() {
  const deleteApi = useDelete();

  async function run(eventId: string) {
    deleteApi.setEndpoint(
      `${process.env.REACT_APP_API_EVENTS}/event/${eventId}/analystnote`
    );
    return deleteApi.run(deleteApi.getOptions());
  }
  return run;
}

export function useGetEventDetails() {
  const getApi = useGet();

  async function run(eventId: string, detectionId: string) {
    getApi.setEndpoint(
      `${process.env.REACT_APP_API_EVENTS}/event/${eventId}/detection/${detectionId}`
    );
    return getApi.run(getApi.getOptions());
  }
  return run;
}

export function useAddEvent() {
  const postApi = usePost();

  async function run(incidentId: string, body: IEventBody) {
    postApi.setEndpoint(
      `${process.env.REACT_APP_API_EVENTS}/events/${incidentId}`
    );
    postApi.setBody(body);
    return postApi.run(postApi.getOptions());
  }
  return run;
}

// PANTHER EVENTS

export function useGetPantherEvents() {
  const getApi = useGet();

  async function run(incidentId: string, nextPageToken: string) {
    const endpoint = `${
      process.env.REACT_APP_API_PANTHER_INCIDENTS
    }/${incidentId}/events${
      nextPageToken ? `?nextpagetoken=${nextPageToken}` : ""
    }`;
    getApi.setEndpoint(endpoint);
    return getApi.runReturnResponse(getApi.getOptions());
  }
  return run;
}

export function useUpdatePantherEvent() {
  const patchApi = usePatch();

  async function run(id: string, body: IEventBody) {
    patchApi.setEndpoint(
      `${process.env.REACT_APP_API_PANTHER_INCIDENTS}/${id}`
    );
    patchApi.setBody(body);
    return patchApi.run(patchApi.getOptions());
  }
  return run;
}

export function useUpdatePantherAnalystNotes(incidentId: string) {
  const patchApi = usePatch();

  async function run(id: string, body: IAnalistNotesBody) {
    patchApi.setEndpoint(
      `${process.env.REACT_APP_API_PANTHER_INCIDENTS}/${incidentId}/events/${id}/analystnote`
    );
    patchApi.setBody(body);
    return patchApi.run(patchApi.getOptions());
  }
  return () => run;
}

export function useDeletePantherAnalystNotes(incidentId: string) {
  const deleteApi = useDelete();

  async function run(eventId: string) {
    deleteApi.setEndpoint(
      `${process.env.REACT_APP_API_PANTHER_INCIDENTS}/${incidentId}/events/${eventId}/analystnote`
    );
    return deleteApi.run(deleteApi.getOptions());
  }
  return () => run;
}

export function useGetPantherEventDetails() {
  const getApi = useGet();
  async function run(incidentId: string, eventId: string, alertId: string) {
    getApi.setEndpoint(
      `${process.env.REACT_APP_API_PANTHER_INCIDENTS}/${incidentId}/events/${eventId}/alerts/${alertId}`
    );
    return getApi.run(getApi.getOptions());
  }
  return run;
}

export function useAddPantherEvent() {
  const postApi = usePost();

  async function run(incidentId: string, body: IEventBody) {
    postApi.setEndpoint(
      `${process.env.REACT_APP_API_PANTHER_INCIDENTS}/${incidentId}`
    );
    postApi.setBody(body);
    return postApi.run(postApi.getOptions());
  }
  return run;
}
