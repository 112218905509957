import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { INCIDENTS_EVENTS } from "../../constants/common.constants";
import { EventDetailInfoProviderContext } from "./eventDetailDataProvider";

export default function useEventDetails() {
  const { incidentId } = useParams();

  const { getIncident, load, incidentURL, data } = useContext(
    EventDetailInfoProviderContext
  );

  useEffect(() => {
    if (Object.keys(data).length) {
      return;
    }

    load();
  }, [data, load]);

  const [title, setTitle] = useState("");
  const loadTitle = useCallback(async () => {
    try {
      const response = await getIncident(`${incidentId}`);
      setTitle(response.title);
    } catch (error: any) {
      console.error(
        `Error getting incident ${incidentId}. Status ${error.status}. ${error}`
      );
    }
  }, [getIncident, incidentId]);

  useEffect(() => {
    if (title.length) {
      return;
    }
    loadTitle();
  });

  const name = useMemo(() => {
    const type: any = {
      [INCIDENTS_EVENTS.DNS_REQUEST.id]: () =>
        INCIDENTS_EVENTS.DNS_REQUEST.text,
      [INCIDENTS_EVENTS.EXISTING_PROCESS.id]: () =>
        INCIDENTS_EVENTS.EXISTING_PROCESS.text,
      [INCIDENTS_EVENTS.MODULE_LOAD.id]: () =>
        INCIDENTS_EVENTS.MODULE_LOAD.text,
      [INCIDENTS_EVENTS.NEW_PROCESS.id]: () =>
        INCIDENTS_EVENTS.NEW_PROCESS.text,
      [INCIDENTS_EVENTS.NETWORK_CONNECTIONS.id]: () =>
        INCIDENTS_EVENTS.NETWORK_CONNECTIONS.text,
      [INCIDENTS_EVENTS.REGISTRY_CREATE.id]: () =>
        INCIDENTS_EVENTS.REGISTRY_CREATE.text,
      [INCIDENTS_EVENTS.REGISTRY_WRITE.id]: () =>
        INCIDENTS_EVENTS.REGISTRY_WRITE.text,
      default: (_type: any) => _type
    };

    if (Reflect.has(data, "routing")) {
      return (type[data.routing.event_type] || type.default)(
        data.routing.event_type
      );
    }

    if (Reflect.has(data, "detect") && Reflect.has(data.detect, "routing")) {
      return (type[data.detect.routing.event_type] || type.default)(
        data.detect.routing.event_type
      );
    }
    if (data?.alertContext?.eventType) {
      return data.alertContext.eventType;
    }
    return "Generic";
  }, [data]);

  return {
    data,
    incidentURL,
    name,
    title
  };
}
