import React, { useCallback, useContext, useMemo } from "react";
import "./incidentsContextMenu.scss";
import { MORE_ICON, STATUS } from "../../constants/common.constants";
import Menu from "../../components/menu/menu";
import useIncidentsContextMenu from "./useIncidentsContextMenu";
import { closeReasonsMapper } from "../../mappers/incidentListMappers";
import IncidentListContext from "../../contexts/incident-list.context";
import IPantherIncidentListContext from "../../contexts/panther-incident-list.context";
import { IIncidentsContextMenuProps } from "../incident-list.types";

export default function IncidentsContextMenu({
  row,
  id,
  switchContext
}: IIncidentsContextMenuProps) {
  const { showClose } = useIncidentsContextMenu();

  const { onClickClose, closeReasons, getIncidentStatus } = useContext(
    switchContext ? IPantherIncidentListContext : IncidentListContext
  );
  const shouldShowClose = showClose(row);

  const shouldShowCloseMenu = useMemo(
    () => Reflect.has(getIncidentStatus(id), STATUS.closed),
    [getIncidentStatus, id]
  );

  const menuItems = useMemo(
    () => [
      {
        id: "close",
        value: "Close",
        hide: switchContext ? !shouldShowCloseMenu : !shouldShowClose,
        subItems: closeReasonsMapper(closeReasons)
      }
    ],
    [switchContext, shouldShowCloseMenu, shouldShowClose, closeReasons]
  );

  const onCloseReasonSelected = useCallback(
    (value: string) =>
      onClickClose({
        value,
        row: row.index,
        id
      }),
    [onClickClose, row, id]
  );
  return (
    <div className="incidentsContextMenuContainer">
      <Menu items={menuItems} clickAction={onCloseReasonSelected} left>
        <img
          className="incidentsContextMenuContainer__icon"
          src={MORE_ICON}
          alt="Menu"
        />
      </Menu>
    </div>
  );
}
